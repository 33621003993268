import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header6'
import Footer from '../layout/footer'

var bnr3 = require('./../../images/banner/solar-header.jpg');

class About2 extends Component {


    render() {
        return (
            <>
                <Header/>
                
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr3 + ")" , backgroundPosition: "center left"}}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">About us</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li>About us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="our-story">
                                            <span>OUR STORY</span>
                                            <h2 className="title">Running a <br/>successful business <br/><span className="text-primary">since 2018</span></h2>
                                            <h4 className="title">Energy Generated From Natural Resources!</h4>
                                            <p>We are a solar energies company based in Telangana, India and we are here to help you choose the best solar rooftop systems, solar water heaters and know how to pay for it. When you buy a solar power system, you need to consider not just the price of the system but also its future benefits. Because every kilowatt hour counts when it comes to reducing emissions and saving money on your electricity bill. In addition to providing financial savings, renewable energy encourages sustainable practices that benefit consumers and reduce our carbon footprints.</p>

<p>SGR Energies is the leading company in Southern India offering Solar On-Grid, Solar Off-Grid, Solar Hybrid and Solar Water Heater. We have incubated a team of 100 professionals having cutting edge skills and have been generating enough business from a decade now. We have become one of the notable names in the market due to our excellent products, customer service, honesty and work ethics.

</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                                        <img src={require("../../images/background/about-solor.jpg")} className="radius-sm" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="section-full box-about-list">
                            <div className="row spno">
                                <div className="col-lg-6 col-md-12"> 
                                    <img src={require("../../images/about/pic3.jpg")} alt="" className="img-cover"/> 
                                </div>
                                <div className="col-lg-6 col-md-12 bg-primary">
                                    <div className="max-w700 m-auto p-tb50 p-lr20">
                                        <div className="text-white">
                                            <h2>We're empowering communities with sustainable solar energy</h2>
                                        </div>
                                        <div className="icon-bx-wraper m-b30 left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-factory"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Solar Panel Engineering</h4>
                                                <p>Crafting state-of-the-art solar panels with cutting-edge technology to maximize energy efficiency and sustainability for our clients.</p>
                                            </div>
                                        </div>
                                        <div className="icon-bx-wraper m-b30 left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-settings"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Renewable Energy Consulting</h4>
                                                <p>Offering expert advice on renewable energy solutions, we guide our clients through every step of their solar journey, ensuring informed decisions for a brighter future.</p>
                                            </div>
                                        </div>
                                        <div className="icon-bx-wraper left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-worker"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Solar Installation & Maintenance</h4>
                                                <p>Providing professional installation services and meticulous maintenance to ensure your solar systems deliver optimal performance and long-lasting benefits.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="title"> Our Services</h2>
                                    <p>SGR Energies is the leading energy solution provider in Southeast Asia. We provide solar on-grid, solar off-grid, solar hybrid and solar water heater solutions.</p>
                                </div>
                                <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12 m-b50 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="dlab-box service-box-3">
                                        <div className="dlab-media radius-sm dlab-img-overlay1 zoom dlab-img-effect"> 
                                            <Link to="/services-details"><img src={require("../../images/our-work/solarplant/solar-on-grid.jpeg")} alt=""/></Link> 
                                        </div>
                                        <div className="dlab-info">
                                            <h4 className="title"><Link to="/services-details">Solar On Grid</Link></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 m-b50 wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="dlab-box service-box-3"> 
                                        <div className="dlab-media radius-sm dlab-img-overlay1 zoom dlab-img-effect"> 
                                            <Link to="/services-details"><img src={require("../../images/our-work/solarplant/pic3.jpg")} alt=""/></Link> 
                                        </div>
                                        <div className="dlab-info">
                                            <h4 className="title"><Link to="/services-details">Solar Off Grid</Link></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 m-b50 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="dlab-box service-box-3">
                                        <div className="dlab-media radius-sm dlab-img-overlay1 zoom dlab-img-effect"> 
                                            <Link to="/services-details"><img src={require("../../images/our-work/solarplant/solar-hybrid.jpeg")} alt=""/></Link> 
                                        </div>
                                        <div className="dlab-info">
                                            <h4 className="title"><Link to="/services-details">Solar Hybrid</Link></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 m-b50 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="dlab-box service-box-3">
                                        <div className="dlab-media radius-sm dlab-img-overlay1 zoom dlab-img-effect"> 
                                            <Link to="/services-details"><img src={require("../../images/our-work/solarplant/water-heater.jpeg")} alt=""/></Link> 
                                        </div>
                                        <div className="dlab-info">
                                            <h4 className="title"><Link to="/services-details">Solar Water Heater</Link></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        
                        <div className="section-full about-progress">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-7 col-md-12 bg-white">
                                        <div className="max-w700  m-auto content-inner">
                                            <div className="section-head">
                                                <h2 className="title">We provide the exceptional service we'd want to experience ourselves</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Solar On Grid Installation<span className="progress-num pull-right">98%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.2s" data-wow-duration="2s" style={{width:"98%"}}></div>
                                                </div>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Solar Hyper<span className="progress-num pull-right">98%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.4s" data-wow-duration="2s" style={{width:"95%"}}></div>
                                                </div>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Maintenance<span className="progress-num pull-right">95%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.6s" data-wow-duration="2s" style={{width:"95%"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12 p-a0">
                                        <img src={require("../../images/about/pic5.jpg")} alt="" className="img-cover "/> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}
export default About2;