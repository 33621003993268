import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header6'
import Footer from '../layout/footer'

const bg = require('../../images/banner/solar-header.jpg')

class Services2 extends Component {


    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt"  style={{ backgroundImage: "url(" + bg + ")", backgroundPosition:"center center" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Services 2</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content-block">
                        <div className="section-full content-inner-2 bg-primary overlay-primary-middle">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 service-main-title text-white text-center">
                                        <h3 className="title max-w700 m-auto">To others what may appear the impossible, becomes possible with Uncode.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="section-full">
                            <div className="row spno about-industry">
                                <div className="col-lg-8">
                                    <img src={require("../../images/our-work/oilgas/pic1.jpg")} alt="" className="img-cover"/>
                                </div>
                                <div className="col-lg-4 bg-white">
                                    <div className="service-box style2">
                                        <div>
                                            <h2 className="title text-black"><span>Solar On Grid</span></h2>
                                            <p>Harness the sun's power with our on-grid solar solutions. Maximize efficiency, reduce bills, and enjoy clean energy. Eco-friendly, reliable, and smart investment for a sustainable future. Easy integration.</p>
                                            <Link to="#" className="site-button outline outline-2">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <img src={require("../../images/our-work/oilgas/pic2.jpg")} alt="" className="img-cover"/>
                                </div>
                                <div className="col-lg-4 bg-secondry text-white">
                                    <div className="service-box style2">
                                        <div>
                                            <h2 className="title"><span>Solar Off Grid</span></h2>
                                            <p>Experience energy independence with our off-grid solar systems. Sustainable living, anywhere. No utility grid required. Robust, self-sufficient power generation. Eco-conscious choice. Expert installation. Tailored for resilience.</p>
                                            <Link to="#" className="site-button outline white outline-2">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <img src={require("../../images/our-work/oilgas/pic3.jpg")} alt="" className="img-cover"/>
                                </div>
                                <div className="col-lg-4 bg-primary text-white">
                                    <div className="service-box style2">
                                        <div>
                                            <h2 className="title"><span>Solar Hybrid  </span> </h2>
                                            <p>Embrace the best of both worlds with our hybrid solar systems. Reliable power, grid-connected with battery backup. Optimize energy use, ensure uninterrupted supply, and save costs. Smart, sustainable technology.</p>
                                            <Link to="#" className="site-button outline white outline-2">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <img src={require("../../images/our-work/oilgas/pic4.jpg")} alt="" className="img-cover"/>
                                </div>
                                <div className="col-lg-4 bg-primary text-white">
                                    <div className="service-box style2">
                                        <div>
                                            <h2 className="title"><span>Solar Water Heater  </span> </h2>
                                            <p>Efficient solar water heating solutions. Eco-friendly, cost-saving. Enjoy hot water year-round. Low maintenance. Renewable energy harnessing. Quick installation. Federal tax credits eligible. Reduce carbon footprint. Sustainable comfort.</p>
                                            <Link to="#" className="site-button outline white outline-2">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer/>                
            </>
        )
    }
}
export default Services2;